<template>
    <v-dialog
        v-model="dialog"
        width="700"
        persistent
    >
        <v-card>
            <v-card-title class="text-h5 secondary primary--text">
                Post Term Reports
            </v-card-title>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="terms"
                    :loading="loading"
                    fixed-header
                    height="50vh"
                >
                    <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title></v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search Term"
                                single-line
                                hide-details
                            ></v-text-field>
                        </v-toolbar>

                        <v-dialog
                            v-model="dialogEdit"
                            max-width="500px"
                            persistent
                        >
                            <v-card
                                v-show="cardPostReport"
                            >
                                <v-card-title></v-card-title>                            
                                <v-card-text
                                    class="text-h6"
                                >
                                    {{ formText }}
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        @click="cancel"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        outlined
                                        @click="removeReports"
                                    >
                                        No
                                    </v-btn>
                                    <v-btn
                                        text
                                        outlined
                                        @click="postReports"
                                    >
                                        Yes
                                    </v-btn>
                                </v-card-actions>
                            </v-card>

                            <v-expand-transition>
                                <v-card
                                    color="primary"
                                    dark
                                    v-show="expand"
                                    class="pa-4"
                                >
                                    <v-card-text>
                                        {{ postingText }}
                                    <v-progress-linear
                                        indeterminate
                                        color="white"
                                        class="mb-0 mt-2"
                                    ></v-progress-linear>
                                    </v-card-text>
                                </v-card>
                            </v-expand-transition>
                        </v-dialog>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn
                            color="primary"
                            outlined
                            @click="editViewTerm(item)"
                            small
                        >
                            <v-icon
                                small
                                class="mr-2"
                            >
                                mdi-pencil
                            </v-icon>
                            Edit
                        </v-btn>
                    </template>

                    <template v-slot:[`item.posted`]="{ item }">
                        <v-icon
                            v-if="item.posted === 0" 
                            small 
                            color="red"
                        >
                            mdi-lock
                        </v-icon>
                        <v-icon
                            v-if="item.posted === 1" 
                            small 
                            color="green"
                        >
                            mdi-check-all
                        </v-icon>
                    </template>
                </v-data-table>
            </v-card-text>

            <v-card-actions class="px-6">
                <v-spacer></v-spacer>
                <v-btn
                    text
                    outlined
                    @click="close"
                >
                    Close
                </v-btn>
            </v-card-actions>
            
            <!-- <v-overlay
                :absolute="true"
                :value="overlay"
            >
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay> -->
        </v-card>
        <v-snackbar
            v-model="snackbar.display"
            :color="snackbar.color"
        >
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar.display = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    props: {
        dialogOpen: Boolean,
    },

    created: function () {
        this.initialize();
    },

    data: function () {
        return {
            overlay: false,
            dialog: this.dialogOpen,
            terms: [],
            headers: [
                { text: 'Year', value: 'year', width: '120' },
                { text: 'Term', value: 'term', width: '60' },
                { text: 'Posted', value: 'posted', width: '80' },
                { text: 'Date Posted', value: 'date_posted' },                
                { text: '', value: 'actions', sortable: false },
            ],
            search: '',
            loading: false,
            dialogEdit: false,
            cardPostReport: true,
            editedItem: {
                year: null,
                term: null,
                termId: null,
                posted: null,
                date_posted: null
            },
            expand: false,
            postingText: '',
            snackbar: {
                display: false,
                text: '',
                color: 'primary'
            }
        }
    },

    computed: {
        formText () {
            return 'Post ' + this.editedItem.year + ' Term ' + this.editedItem.term + ' reports?';
        },
    },

    methods: {
        async initialize () {
            this.loading = true;
            try {
                const { data } = await this.getTermReports();
                // console.log(data);
                this.mapTermReports(data);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.loading = false;
        },

        mapTermReports (data) {
            // let posted = null; 
            let academicYearId = null;
            this.terms = [];
            // console.log(data);
            data.forEach(record => {
                // console.log(data[record]);
                academicYearId = record.academic_year_id.toString();
                // posted = 'No';
                // if(record.posted == 1) posted = 'Yes';
                this.terms.push({
                    year: academicYearId.substr(0,4) + '-' + academicYearId.substr(4),
                    term: record.term,
                    termId: record.academic_term_id,
                    posted: record.posted,
                    date_posted: record.date_posted
                })
               
            })

            // console.log(this.terms);
        },

        ...mapActions({
            getTermReports: 'settings/getTermReports',
            postTermReports: 'settings/postTermReports',
        }),

        ...mapMutations({
            setEditedTerm: 'settings/setEditedTerm',
        }),

        editViewTerm (item) {
            this.editedItem = item;
            this.cardPostReport = true;
            this.dialogEdit = true;
        },

        close () {
            this.$emit('dialog-close');
        },

        cancel(){
            this.dialogEdit = false;
        },

        async postReports(){
            this.cardPostReport = false;
            this.postingText = 'Posting reports please stand by'
            this.$nextTick(()=>{
                this.expand = true;
            })
            let termRecord = Object.assign({}, this.editedItem);
            termRecord['posted'] = 1;
            let date = new Date();
            termRecord['date_posted'] = date.toLocaleString("sv-SE");
            this.setEditedTerm(termRecord);            
            try {
                await this.postTermReports();
                this.editedItem['posted'] = 1;
                this.editedItem['date_posted'] = date.toLocaleString("sv-SE");
                this.expand = false;
                this.$nextTick(()=>{
                    this.dialogEdit = false;
                })
                
                setTimeout(() => {
                    this.snackbar.text = this.editedItem.year + ' Term ' + this.editedItem.term + ' reports posted'                    
                    this.snackbar.display = true;
                })
                //console.log(response)
            } catch (error) {
                this.expand = false;
                this.dialogEdit = false;
                setTimeout(() => {
                    this.snackbar.text = `Unable to post. An error Occured. ${error?.response?.data.message}`                   
                    this.snackbar.color = 'red';
                    this.snackbar.display = true;
                })
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        async removeReports(){
            this.cardPostReport = false;
            this.postingText = 'Locking reports please stand by'
            this.$nextTick(()=>{
                this.expand = true;
            })
            let termRecord = Object.assign({}, this.editedItem);
            termRecord['posted'] = 0;            
            termRecord['date_posted'] = null;
            //console.log(termRecord);
            this.setEditedTerm(termRecord);            
            try {
                await this.postTermReports();
                this.editedItem['posted'] = 0;
                this.editedItem['date_posted'] = null;
                this.expand = false;
                this.$nextTick(()=>{
                    this.dialogEdit = false;
                })
                
                setTimeout(() => {
                    this.snackbar.text = this.editedItem.year + ' Term ' + this.editedItem.term + ' reports locked'
                    this.snackbar.display = true;
                })
                //console.log(response)
            } catch (error) {
                this.expand = false;
                this.dialogEdit = false;
                 setTimeout(() => {
                    this.snackbar.text = 'Unable to edit. An error occured.'
                    this.snackbar.display = true;
                })
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        }
    }
}
</script>