import axios from 'axios'

export default {
    namespaced: true,
    state: {
        subjectSelected: null,
        studentSelected: null,
        subjectStudentAssignments: [],
    },
    getters: {
        getSubjectSelected(state){
            return state.subjectSelected
        },
        getStudentSelected(state){
            return state.studentSelected
        },

        getSubjectStudentAssignments(state){
            return state.subjectStudentAssignments
        }
    },
    mutations: {
        setSubjectSelected(state, value){
            state.subjectSelected = value
        },
        setStudentSelected(state, value){
            state.studentSelected = value
        },

        setSubjectStudentAssignments(state, value){
            state.subjectStudentAssignments = value
        },
    },
    actions: {
        getStudents(){
            let url = '/api/student-subject-assignments-students';
            return axios.get(url);
        },
        getStudentsAssigned({ getters }){
            const url = '/api/student-subject-assignments'
            return axios.get(url, { params: {
                'subject_id': getters.getSubjectSelected
            }});
        },
        deleteSubjectStudentAssignment({ getters }){
            const url = '/api/student-subject-assignment';
            return axios.delete(url, { params: getters.getSubjectStudentAssignments });
        },
        postSubjectStudentAssignments ({ getters }) {
            const url = 'api/subject-student-assignments';    
            return axios.post(url, getters.getSubjectStudentAssignments);
        },

        getSubjects () {
            const url = '/api/student-subject-assignments-subjects';
            return axios.get(url);
        },

        getFormClasses () {
            const url = '/api/student-subject-assignments-form-classes';
            return axios.get(url);
        },

        getTeachers () {
            const url = '/api/student-subject-assignments-teachers';
            return axios.get(url);
        }
    }
}